import React from "react";
import Login from "./Login";
import Register from "./Register";

function Hero() {
  return (
    <section className="pt-150 pb-150">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="row">
              <Login />
              <div className="col-lg-1"></div>
              <Register />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
