import React, { useEffect } from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import Main from "../components/LoginRegister/Main";
import { Helmet } from "react-helmet";

function LoginRegister() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
        <title>Login - Fazal Shawl</title>
        <meta name="description" content="Login to your Fazal Shawl account to access exclusive features and benefits. Sign in to explore our latest collections and place orders conveniently." />
        <meta name="keywords" content="Fazal Shawl, login, sign up, account, fashion, shawls" />
      </Helmet>
      <Header />
      <BreadCrumb title="Login & Register" />
      <Main />
      <Footer />
    </>
  );
}

export default LoginRegister;
