import React from "react";
import { Link } from "react-router-dom";

function BreadCrumb(props) {
  return (
    <div className="page-header breadcrumb-wrap">
      <div className="container">
        <div className="breadcrumb">
          <Link to={'/'}>
            Home
          </Link>
          <span></span> {props.title}
        </div>
      </div>
    </div>
  );
}

export default BreadCrumb;
