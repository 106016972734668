import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function ErrorPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="main page-404">
      <div className="container">
        <div className="row align-items-center height-100vh text-center">
          <div className="col-lg-8 m-auto">
            <p className="mb-50">
              <img
                src="assets/imgs/theme/404.png"
                alt=""
                className="hover-up"
              />
            </p>
            <h2 className="mb-30">Page Not Found</h2>
            <p className="font-lg text-grey-700 mb-30">
              The link you clicked may be broken or the page may have been
              removed.
              <br /> visit the
              <a href={'/'}>
                <span> Homepage</span>
              </a>
              or
              <Link to={'/contact-us'}>
                <span>Contact us</span>
              </Link>
              about the problem
            </p>
            
              
              <Link to={'/'}
                className="btn btn-default submit-auto-width font-xs hover-up"
              >
                Back To Home Page
              </Link>
      
          </div>
        </div>
      </div>
    </main>
  );
}

export default ErrorPage;
