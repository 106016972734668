import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PopularCards from "./PopularCards";
import { setAllCategories } from "../../redux/actions/allcategories";
import { useDispatch } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";

function PopularCategories() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllCategories();
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, []);

  const getAllCategories = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/category/getAll`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setAllCategories(result.data));
        setCategories(result.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <section className="popular-categories section-padding mt-15 mb-25">
      <div className="container wow fadeIn animated">
        <h3 className="section-title mb-20">
          <span>Popular</span> Categories
        </h3>
        <div className="carausel-6-columns-cover position-relative">
          <div className="row">
            {categories.map((category) => (
              <PopularCards key={category.id} category={category} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopularCategories;
