import React, { useEffect } from "react";
import Main from "../components/home/Main";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Fazal Shawl - Home</title>
        <meta
          name="description"
          content="Welcome to Fazal Shawl, where elegance meets warmth. Explore our exquisite collection of hand-woven shawls crafted from the finest materials. Embrace comfort and style with Fazal Shawl."
        />
        <meta
          name="keywords"
          content="Fazal Shawl, shawls, hand-woven shawls, elegance, warmth, comfort, style"
        />
      </Helmet>
      <Header />
      <Main />
      <Footer />
    </>
  );
}

export default Home;
