import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function CartHero() {
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const shippingCost = 200;

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(cart);

    const calcSubtotal = cart.reduce(
      (acc, item) => acc + item.salePrice * item.quantity,
      0
    );
    setSubtotal(calcSubtotal);
  }, []);

  const handleRemoveFromCart = (productId) => {
    const updatedCart = cartItems.filter((item) => item.id !== productId);
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setSubtotal(
      updatedCart.reduce((acc, item) => acc + item.salePrice * item.quantity, 0)
    );
  };

  const handleQuantityChange = (productId, delta) => {
    const updatedCart = cartItems.map((item) => {
      if (item.id === productId) {
        const updatedQuantity = item.quantity + delta;
        return { ...item, quantity: updatedQuantity > 0 ? updatedQuantity : 1 };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setSubtotal(
      updatedCart.reduce((acc, item) => acc + item.salePrice * item.quantity, 0)
    );
  };

  const total = subtotal + shippingCost;

  return (
    <>
      {cartItems.length > 0 ? (
        <div className="table-responsive">
          <table className="table shopping-summery text-center clean">
            <thead>
              <tr className="main-heading">
                <th scope="col ">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Subtotal</th>
                <th scope="col">Remove</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, index) => (
                <tr key={index}>
                  <td className="image product-thumbnail">
                    <img
                      src={`https://admin.fazalshawl.com/products/${item?.thumbnail}`}
                      alt="#"
                      style={{ width: "100px" }}
                    />
                  </td>
                  <td className="product-des product-name">
                    <h5 className="product-name">{item.title}</h5>
                  </td>
                  <td className="price" data-title="Price">
                    <span>Rs.{item.salePrice}</span>
                  </td>
                  <td className="qty" data-title="Quantity">
                    <a
                      href="##"
                      onClick={(e) => {
                        e.preventDefault();
                        handleQuantityChange(item.id, -1);
                      }}
                      className="qty-down"
                    >
                      <i className="fi-rs-angle-small-down"></i>
                    </a>
                    <span className="qty-val">{item.quantity}</span>
                    <a
                      href="##"
                      onClick={(e) => {
                        e.preventDefault();
                        handleQuantityChange(item.id, 1);
                      }}
                      className="qty-up"
                    >
                      <i className="fi-rs-angle-small-up"></i>
                    </a>
                  </td>
                  <td className="text-right" data-title="Subtotal">
                    <span>
                      Rs.{(item.salePrice * item.quantity).toFixed(2)}
                    </span>
                  </td>
                  <td className="action" data-title="Remove">
                    <a
                      href="##"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveFromCart(item.id);
                      }}
                      className="text-muted"
                    >
                      <i className="fi-rs-trash"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center ">
          <p>Your cart is currently empty.</p>
          <Link to={"/shop"} className="btn btn-brand">
            <i className="fi-rs-shopping-bag mr-10"></i>Start Shoping
          </Link>
        </div>
      )}
      {cartItems.length > 0 && (
        <div className="row mb-50">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div className="mt-50 mt-md-0 cart-totals">
              <div className="heading_s1 mb-3">
                <h4>Cart Totals</h4>
              </div>
              <div className="table-responsive">
                <table className="">
                  <tbody>
                    <tr>
                      <td className="cart_total_label">Cart Subtotal</td>
                      <td className="cart_total_amount">
                        <span className="font-lg fw-900 text-brand">
                          Rs.{subtotal.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="cart_total_label">Shipping</td>
                      <td className="cart_total_amount">
                        <i className="ti-gift mr-5"></i> Rs.{shippingCost}
                      </td>
                    </tr>
                    <tr>
                      <td className="cart_total_label">Total</td>
                      <td className="cart_total_amount">
                        <strong>
                          <span className="font-xl fw-900 text-brand">
                            Rs.{total.toFixed(2)}
                          </span>
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between">
                <Link to={"/checkout"} className="btn btn-brand">
                  <i className="fi-rs-box-alt mr-10"></i>Proceed To CheckOut
                </Link>
                <Link to={"/shop"} className="btn btn-brand">
                  <i className="fi-rs-shopping-bag mr-10"></i>Continue Shoping
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CartHero;
