import React, { useEffect } from "react";
import Header from "../components/common/Header";
import BreadCrumb from "../components/common/BreadCrumb";
import Footer from "../components/common/Footer";
import Main from "../components/MyAccount/Main";

function MyAccount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <BreadCrumb title="My Account" />
      <Main />
      <Footer />
    </>
  );
}

export default MyAccount;
