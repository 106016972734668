import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/common/Header";
import BreadCrumb from "../components/common/BreadCrumb";
import Footer from "../components/common/Footer";
import Main from "../components/contact/Main";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact Us - Fazal Shawl</title>
        <meta name="description" content="Contact Fazal Shawl for inquiries, feedback, or any assistance you may need. Our team is here to provide you with the best customer service experience." />
        <meta name="keywords" content="contact us, customer service, inquiries, feedback, Fazal Shawl" />
      </Helmet>
      <Header />
      <BreadCrumb title="Contact Us" />
      <Main />
      <Footer />
    </>
  );
}

export default Contact;
