import React from "react";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";

function Mobileheader() {
  return (
    <div className="mobile-header-active mobile-header-wrapper-style">
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-top">
          <div className="mobile-header-logo">
            <a href="/">
              <img
                style={{ width: "200px" }}
                src="assets/imgs/theme/logo.png"
                alt="logo"
              />
            </a>
          </div>
          <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
            <button className="close-style search-close">
              <i className="icon-top"></i>
              <i className="icon-bottom"></i>
            </button>
          </div>
        </div>
        <SearchBar />
        <div className="mobile-header-content-area">
          <div className="mobile-menu-wrap mobile-header-border">
            <nav>
              <ul className="mobile-menu">
                <li className="menu-item-has-children">
                  <span className="menu-expand"></span>
                  <a href={"/"}>Home</a>
                </li>
                <li className="menu-item-has-children">
                  <span className="menu-expand"></span>
                  <Link to={"/about-us"}>About</Link>
                </li>
                <li className="menu-item-has-children">
                  <span className="menu-expand"></span>
                  <Link to={"/shop"}> Shop</Link>
                </li>
                <li className="menu-item-has-children">
                  <span className="menu-expand"></span>
                  <Link to={"/contact-us"}>Contact us</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="mobile-header-info-wrap mobile-header-border">
            <div className="single-mobile-header-info">
              <Link to={"/login-register"}>Log In / Sign Up </Link>
            </div>
            <div className="single-mobile-header-info">
              <div>(+92) - 300 - 6057930</div>
            </div>
          </div>
          <div className="mobile-social-icon">
            <h5 className="mb-15 text-grey-4">Follow Us</h5>
            <a
              href="https://www.facebook.com/fazalshawl"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/imgs/theme/icons/icon-facebook.svg"
                alt="facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/fazal_shawl/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/imgs/theme/icons/icon-instagram.svg"
                alt="Instagram"
              />
            </a>
          </div>
        </div>
      </div>
      <p className="text-center font-sm text-muted mb-0">
        Designed by
        <a href="https://codeflamme.com/" target="_blank" rel="noreferrer">
          Code Flamme
        </a>
        . All rights reserved 2024
      </p>
    </div>
  );
}

export default Mobileheader;
