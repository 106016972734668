import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setWishList } from "../../redux/actions/wishlist";

function Main() {
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishList.wishlist);
  const userData = useSelector((state) => state.userDetails.user);

  const removeFromWishlist = (productId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("productId", productId);
    urlencoded.append("userId", userData?.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://apis.fazalshawl.com/user/wishlist/removeFromWishlist",
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        dispatch(setWishList(response.data));
        toast("Deleted Successfully");
      })
      .catch((error) => console.log("error", error));
  };

  const handleAddToCart = (product) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProductIndex = cart.findIndex(
      (item) => item.id === product.id
    );
    if (existingProductIndex >= 0) {
      cart[existingProductIndex].quantity += 1;
      toast("Product Qty +1");
    } else {
      cart.push({ ...product, quantity: 1 });
      toast("Product added to cart");
    }
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  if (!localStorage.getItem("id")) {
    return (
      <section className="height-50vh">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mt-200 ">
              <h5>Please login to view your wishlist.</h5>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="mt-50 mb-50">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {wishlist.length === 0 ? (
              <p className="text-center height-50vh">Your wishlist is empty.</p>
            ) : (
              <div className="table-responsive">
                <table className="table shopping-summery text-center">
                  <thead>
                    <tr className="main-heading">
                      <th scope="col" colspan="2">
                        Product
                      </th>
                      <th scope="col">Price</th>
                      <th scope="col">Stock Status</th>
                      <th scope="col">Action</th>
                      <th scope="col">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wishlist.map((item) => (
                      <tr key={item.id}>
                        <td className="image product-thumbnail">
                          <img
                            src={`https://admin.fazalshawl.com/products/${item.product.thumbnail}`}
                            alt="product IMG"
                          />
                        </td>
                        <td className="product-des product-name">
                          <h5 className="product-name">
                            <a href="shop-product-right.html">
                              {item?.product?.title}
                            </a>
                          </h5>
                          <p className="font-xs">
                            {item?.product?.description}.
                          </p>
                        </td>
                        <td className="price" data-title="Price">
                          <span>Rs. {item?.product?.salePrice}</span>
                        </td>
                        <td className="product-stock-status">
                          {item?.product?.quantity - item?.product?.sale ===
                          0 ? (
                            <span>Out of Stock</span>
                          ) : (
                            <span className="stock">In Stock</span>
                          )}
                        </td>
                        <td className="text-right" data-title="Cart">
                          <button
                            onClick={() => handleAddToCart(item.product)}
                            className="btn btn-brand"
                          >
                            <i className="fi-rs-shopping-bag mr-5"></i>Add to
                            cart
                          </button>
                        </td>
                        <td className="action" data-title="Remove">
                          <button
                            className="btn btn-brand"
                            onClick={() => removeFromWishlist(item.product.id)}
                          >
                            <i className="fi-rs-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer/>
    </section>
  );
}

export default Main;
