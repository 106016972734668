import React, { useEffect } from "react";
import $ from "jquery";
import TopHeader from "./TopHeader";
import Navbar from "./Navbar";
import Mobileheader from "./Mobileheader";

function Header() {
  useEffect(() => {
    const mobileHeaderActive = () => {
      const navbarTrigger = $(".burger-icon"),
        endTrigger = $(".mobile-menu-close"),
        container = $(".mobile-header-active"),
        wrapper4 = $("body");

      wrapper4.prepend('<div class="body-overlay-1"></div>');

      navbarTrigger.on("click", function (e) {
        e.preventDefault();
        container.addClass("sidebar-visible");
        wrapper4.addClass("mobile-menu-active");
      });

      endTrigger.on("click", function () {
        container.removeClass("sidebar-visible");
        wrapper4.removeClass("mobile-menu-active");
      });

      $(".body-overlay-1").on("click", function () {
        container.removeClass("sidebar-visible");
        wrapper4.removeClass("mobile-menu-active");
      });
    };

    mobileHeaderActive();
    return () => {
      $(".burger-icon").off("click");
      $(".mobile-menu-close").off("click");
      $(".body-overlay-1").remove();
    };
  }, []);
  return (
    <>
      <header className="header-area header-style-1 header-height-2">
        <TopHeader />
        <Navbar />
      </header>
      <Mobileheader/>
    </>
  );
}

export default Header;
