import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert2";

function Footer() {
  const [credentials, setCredentials] = useState({
    email: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validations
    if (credentials.email.length === 0) {
      swal.fire("Oops...", "Please enter Email", "error");
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("email", credentials.email);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/newsletter/addRecord`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            swal.fire("Subscribed", "", "success");
            setCredentials({
              email: "",
            });
          } else if (response.status === "error") {
            swal.fire("Oops...", response.message, "error");
            setCredentials({
              email: "",
            });
          } else {
            swal.fire("Oops...", "Internal Server Error", "error");
          }
        })
        .catch((error) => {
          console.error(error);
          swal.fire("Oops...", "Internal Server Error", "error");
        });
    } catch (error) {
      console.error(error);
      swal.fire("Oops...", "Internal Server Error", "error");
    }
  };
  return (
    <footer className="main">
      <section className="section-padding footer-mid">
        <div className="container pt-15 pb-20">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="widget-about font-md mb-md-5 mb-lg-0">
                <div className="logo logo-width-1 wow fadeIn animated">
                  <a href="/">
                    <img
                      className="w-100"
                      src="../assets/imgs/theme/logo.png"
                      alt="logo"
                    />
                  </a>
                </div>
                <h5 className="mt-20 mb-10 fw-600 text-grey-4 wow fadeIn animated">
                  Fazal Cloth And Shal House has been weaving elegance and
                  warmth since 1978. Our heritage is steeped in the art of shawl
                  craftsmanship.{" "}
                </h5>
                <p className="wow fadeIn animated">
                  <strong>Address: </strong>Block no 9, Bangash Bara Market
                  Sargodha
                </p>
                <p className="wow fadeIn animated">
                  <strong>Email: </strong>
                  <a href="mailto://support@fazalshawl.com">
                    support@fazalshawl.com
                  </a>
                </p>
                <h5 className="mb-10 mt-30 fw-600 text-grey-4 wow fadeIn animated">
                  Follow Us
                </h5>
                <div className="mobile-social-icon wow fadeIn animated mb-sm-5 mb-md-0">
                  <a
                    href="https://www.facebook.com/fazalshawl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="../assets/imgs/theme/icons/icon-facebook.svg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/fazal_shawl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="../assets/imgs/theme/icons/icon-instagram.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <h5 className="widget-title wow fadeIn animated">About</h5>
              <ul className="footer-list wow fadeIn animated mb-sm-5 mb-md-0">
                <li>
                  <Link to={"/about-us"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/shop"}>Shop</Link>
                </li>
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
                <li>
                  <Link to={"/login-register"}>Register Now</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <h5 className="widget-title wow fadeIn animated">My Account</h5>
              <ul className="footer-list wow fadeIn animated">
                <li>
                  <Link to={"/login-register"}>Sign In</Link>
                </li>
                <li>
                  <Link to={"/cart"}>View Cart</Link>
                </li>
                <li>
                  <Link to={"/wishlist"}>My Wishlist</Link>
                </li>
                <li>
                  <Link to={"/my-account"}>Track My Order</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <h5 className="widget-title wow fadeIn animated">Newsletter</h5>
              <h5 className="mt-20 mb-10 fw-600 text-grey-4 wow fadeIn animated">
                Sign up for our newsletter & promotions
              </h5>
              <div className="row">
                <div className="col-lg-12">
                  <form
                    className="form-subcriber d-flex wow fadeIn animated mt-5"
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="email"
                      name="email"
                      className="form-control bg-white font-small"
                      placeholder="email@example.com"
                      onChange={onChange}
                      value={credentials.email}
                    />
                    <button className="btn bg-dark text-white" type="submit">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container pb-20 wow fadeIn animated">
        <div className="row">
          <div className="col-12 mb-20">
            <div className="footer-bottom"></div>
          </div>
          <p className="text-center font-sm text-muted mb-0">
            Developed by{" "}
            <a href="https://codeflamme.com/" target="_blank" rel="noreferrer">
              Code Flamme
            </a>
            . All rights reserved 2024
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
