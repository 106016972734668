import React from "react";

function Hero() {
  return (
    <section className="section-padding my-5">
      <div className="container pt-25">
        <div className="row">
          <div className="col-lg-7 align-self-center mb-lg-0 mb-4">
            <h2 className="mt-0 mb-15 text-uppercase  text-brand wow fadeIn animated">
              Our Company
            </h2>
            <p>
              Fazal Shawl has been weaving elegance and warmth since 1978. Our
              heritage is steeped in the art of shawl craftsmanship, passed down
              through generations. For over four decades, we have perfected the
              timeless craft, creating shawls for both men and women that
              epitomize comfort and style. Quality is at the core of everything
              we do. We meticulously select the finest materials, including
              cashmere, wool, and silk, to ensure that every Fazal Shawl is soft
              to the touch and a joy to wear. Our skilled artisans hand-weave
              and hand-embroider each piece, upholding the highest standards of
              craftsmanship.
            </p>
            <p>
              Our collection caters to all seasons. Whether it's a light and
              breathable shawl for summer or a cozy winter accessory, Fazal
              Shawl has you covered. We take pride in our commitment to
              sustainability, using eco-friendly materials and ethical
              manufacturing practices. Each Fazal Shawl not only enhances your
              wardrobe but also contributes to a better planet. Our customers
              are at the heart of our business. We aim to exceed your
              expectations with every purchase, offering an elegant and
              memorable shopping experience. Join us in celebrating the
              tradition of Fazal Shawl. Explore our collection and experience
              the timeless beauty and craftsmanship that define us. Discover the
              world of Fazal Shawl today.
            </p>
          </div>
          <div className="col-lg-5">
            <img className="rounded"  src="assets/imgs/page/1.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
