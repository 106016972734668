import React from "react";
import LeftProduct from "./LeftProduct";

function Main() {
  return (
    <section className="mt-50 mb-50">
      <div className="container">
        <div className="row">
          <LeftProduct />
        </div>
      </div>
    </section>
  );
}

export default Main;
