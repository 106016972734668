import React from "react";

function CategorySidebar({subcat}) {
  return (
    <div className="col-lg-3 primary-sidebar sticky-sidebar order-1 order-lg-2">
      <div className="row">
        <div className="col-lg-12 col-mg-6"></div>
        <div className="col-lg-12 col-mg-6"></div>
      </div>
      <div className="widget-category mb-30">
        <h5 className="section-title style-1 mb-30 wow fadeIn animated">
          Category
        </h5>
        <ul className="categories">
        {subcat?.map((product) => (
          <li>
            <a href={`../subcategory/${product?.name}`}>{product.name}</a>
          </li>
              ))}
        </ul>
      </div>
    </div>
  );
}

export default CategorySidebar;
