import React, { useEffect } from "react";
import Header from "../components/common/Header";
import BreadCrumb from "../components/common/BreadCrumb";
import Footer from "../components/common/Footer";
import Main from "../components/wishlist/Main";

function Wishlist() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <main className="main">
        <BreadCrumb title="Wishlist" />
      </main>
      <Main />
      <Footer />
    </>
  );
}

export default Wishlist;
