import React from "react";
import ShopCard from "./ShopCard";
import { useSelector } from "react-redux";

function Grid() {
  const getAllProducts = useSelector((state) => state.allProduct.allproduct);
  return (
    <>
      <div className="row product-grid-3">
        {getAllProducts?.map((product) => (
          <ShopCard product={product} key={product.id} />
        ))}
      </div>
    </>
  );
}

export default Grid;
