import React from "react";
import AddReview from "./AddReview";
import moment from "moment";

function AdditionalInfo(props) {
  const formatDate = (createdAt) => {
    return moment(createdAt).format("MMMM D, YYYY");
  };
  const calculateRatingWidth = (rating) => {
    return `${rating * 20}%`;
  };
  return (
    <div className="tab-style3">
      <ul className="nav nav-tabs text-uppercase">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="Description-tab"
            data-bs-toggle="tab"
            href="#Description"
          >
            Description
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="Reviews-tab"
            data-bs-toggle="tab"
            href="#Reviews"
          >
            Reviews
          </a>
        </li>
      </ul>
      <div className="tab-content shop_info_tab entry-main-content">
        <div className="tab-pane fade show active" id="Description">
          <div className="">
            <p>
              At Fazal Shawl, we are committed to providing a seamless and
              efficient shipping experience for our customers in Pakistan.
              Please take a moment to familiarize yourself with our shipping
              policy, which includes important details about shipping fees,
              delivery times, and other essential information.
            </p>
            <ul className="product-more-infor mt-30">
              <li>
                <span>2-4 business days (Typically by end of day)</span>
              </li>
              <li>
                <span>Delivery only Avaliable in Pakistan</span>
              </li>
              <li>
                <span>24/7 live support</span>
              </li>
            </ul>
            <hr className="wp-block-separator is-style-dots" />
            <p>
              Shipping Fee: For all orders shipped to addresses within Pakistan,
              a flat shipping fee of PKR 199 will apply. This fee is subject to
              change, and any updates will be clearly communicated during the
              checkout process. irrespective limpet.
            </p>
            <hr className="wp-block-separator is-style-wide" />
            <p>
              Order Processing Time: Once your order is placed and payment is
              confirmed, it will undergo processing. Typically, orders are
              processed within business days. Please note that processing times
              may vary during peak seasons or due to unforeseen circumstances.
              We will make every effort to keep you informed in such cases.
            </p>
            <p>
              Customer Support: Our customer support team is here to assist you
              with any shipping-related inquiries. If you have questions about
              your order, shipping fees, or any other concerns, please don't
              hesitate to contact our support team. Policy Updates: This
              shipping policy is subject to change. Any updates or modifications
              will be posted on our website, and the revised policy will apply
              to all new orders. Thank you for choosing Fazal Shawl for your
              shopping needs. We appreciate your business and aim to make your
              online shopping experience as convenient as possible. If you have
              any further questions or need assistance with your order, please
              do not hesitate to contact us.
            </p>
          </div>
        </div>

        <div className="tab-pane fade" id="Reviews">
          <div className="comments-area">
            <div className="row">
              <div className="col-lg-8">
                <h4 className="mb-30">Customer Feedback</h4>
                <div className="comment-list">
                  {props?.reviewData?.length > 0 ? (
                    props?.reviewData?.map((review, index) => (
                      <div
                        key={index}
                        className="single-comment justify-content-between d-flex"
                      >
                        <div className="user justify-content-between d-flex">
                          <div className="desc">
                            <div className="product-rate d-inline-block">
                              <div
                                className="product-rating"
                                style={{
                                  width: calculateRatingWidth(review?.rating),
                                }}
                              ></div>
                            </div>
                            <h5>{review?.username}:</h5>
                            <p className="ms-5">{review?.review}</p>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <p className="font-xs mr-30">
                                  {formatDate(review?.createdAt)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <p>
                        Be the first to share your thoughts or review this
                        product!
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <AddReview id={props.id} />
        </div>
      </div>
    </div>
  );
}

export default AdditionalInfo;
