import React from "react";
import Map from "./Map";
import Form from "./Form";

function Main() {
  return (
    <main className="main">
      <Map />
      <Form />
    </main>
  );
}

export default Main;
