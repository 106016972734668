import React from "react";
import ShopCard from "./ShopCard";
import { useNavigate } from "react-router-dom"; 

function Grid({products}) {
  const navigate = useNavigate();
  return (
    <>
      <button onClick={() => navigate(-1)} className="btn btn-brush ms-0 ps-0">Go Back</button>
      <div className="row product-grid-3">
        {products?.map((product) => (
          <ShopCard product={product} key={product.id} />
        ))}
      </div>
    </>
  );
}

export default Grid;
