import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation

function SearchBar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      if (searchTerm.length < 3) { // Example condition: Only search if searchTerm has 3 or more characters
        setSuggestions([]);
        setShowSuggestions(false);
        return;
      }

      const encodedTitle = encodeURIComponent(searchTerm);
      const url = `https://apis.fazalshawl.com/user/product/search?title=${encodedTitle}`;
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.data) {
          setSuggestions(result.data);
          setShowSuggestions(true);
        }
      } catch (error) {
        console.error('Fetching error:', error);
        setSuggestions([]);
        setShowSuggestions(false);
      }
    };

    const timerId = setTimeout(() => {
      fetchData();
    }, 500); // Debouncing the search to reduce API calls

    return () => clearTimeout(timerId); // Cleanup the timeout on effect cleanup
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.title);
    setShowSuggestions(false);
  };
  
  return (
    <div>
      <div className="search-style-2 ms-5 ">
        <form action="#">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search for items..."
          />
          {showSuggestions && suggestions.length > 0 && (
            <div className="suggestions">
              {suggestions.map((suggestion, index) => (
                <Link
                  key={index}
                  to={`/product/${suggestion?.id}`}
                  className="suggestion-title"
                >
                  <div
                    className="suggestion-item"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <img
                      src={`https://admin.fazalshawl.com/products/${suggestion?.thumbnail}`}
                      alt={suggestion.title}
                      className="suggestion-image"
                    />
                    <div className="suggestion-info">
                      <h5>
                        <strong>{suggestion.title}</strong>
                      </h5>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default SearchBar;
