import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert2";

function AddReview(props) {
  const [credentials, setCredentials] = useState({
    username: "",
    email: "",
    review: "",
    rating: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!credentials.username) {
      toast.error("Please enter your name.");
      return;
    }
    if (!credentials.email) {
      toast.error("Please enter your email.");
      return;
    }
    if (!credentials.review) {
      toast.error("Please enter your review.");
      return;
    }
    if (!credentials.rating) {
      toast.error("Please choose a rating.");
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("productId", props.id);
      urlencoded.append("username", credentials.username);
      urlencoded.append("email", credentials.email);
      urlencoded.append("review", credentials.review);
      urlencoded.append("rating", credentials.rating);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_API_URL}user/review/addRecord`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            swal.fire("Review Submitted", "", "success");
            setCredentials({
              username: "",
              email: "",
              review: "",
              rating: "",
            }); 
          } 
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("An error occurred while submitting the review.");
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error("An unexpected error occurred.");
    }
  };

  return (
    <div>
      <div className="comment-form">
        <h4 className="mb-15">Add a review</h4>
        <form className="form-contact comment_form" onSubmit={handleSubmit} id="commentForm">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <input className="form-control" name="username" onChange={onChange} value={credentials.username} type="text" placeholder="Name" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input className="form-control" name="email" onChange={onChange} value={credentials.email} type="email" placeholder="Email" />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <select className="form-control" name="rating" value={credentials.rating} onChange={onChange}>
                  <option value="" disabled>
                    Choose Any
                  </option>
                  <option value="1">Worst</option>
                  <option value="2">Bad</option>
                  <option value="3">Average</option>
                  <option value="4">Good</option>
                  <option value="5">Best</option>
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <textarea className="form-control w-100" name="review" onChange={onChange} value={credentials.review} cols="30" rows="9" placeholder="Write Comment"></textarea>
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-brand">Submit Review</button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddReview
