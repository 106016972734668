import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import BreadCrumb from '../components/common/BreadCrumb';
import Footer from '../components/common/Footer';
import Main from '../components/checkout/Main';

function Checkout() {
  const navigate = useNavigate();

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart'));
    if (!cart || cart.length === 0) {
      navigate('/cart'); 
    }
  }, [navigate]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <BreadCrumb title="Checkout" />
      <Main />
      <Footer />
    </>
  );
}

export default Checkout;
