import React from "react";
import { Link } from "react-router-dom";

function TopHeader() {
  const isLoggedIn = localStorage.getItem("id");
  return (
    <div className="header-top header-top-ptb-1 d-none d-lg-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-3 col-lg-4">
            <div className="header-info">
              <ul>
                <li>
                  <i className="fi-rs-smartphone"></i>{" "}
                  <div>(+92) - 300 - 6057930</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6 col-lg-4">
            <div className="text-center">
              <div id="news-flash" className="d-inline-block">
                <ul>
                  <li>Get great discount up to 50% off</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4">
            <div className="header-info header-info-right">
              <ul>
                {isLoggedIn ? (
                  <li>
                    <i className="fi-rs-user"></i>
                    <Link to={"/my-account"}>Account Settings</Link>
                  </li>
                ) : (
                  <li>
                    <i className="fi-rs-user"></i>
                    <Link to={"/login-register"}>Log In / Sign Up</Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
