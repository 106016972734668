import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { bestsellerReducer } from "./bestsellerReducer";
import { mostviewReducer } from "./mostviewReducer";
import { newarivalProductReducer } from "./newarivalProductReducer";
import { allproductReducer } from "./allproductReducer";
import { allCategoriesReducer } from "./allCategoriesReducer";
import { topratedReducer } from "./topratedReducer";
import { wishlistReducer } from "./wishListReducer";
import { orderlistReducer } from "./orderListReducer";
import { cartReducer } from "./cartReducer";

const reducers = combineReducers({
    userDetails: userReducer,
    bestSeller: bestsellerReducer,
    mostView: mostviewReducer,
    newarivalProduct: newarivalProductReducer,
    allProduct: allproductReducer,
    allCategories: allCategoriesReducer,
    topRated: topratedReducer,
    wishList: wishlistReducer,
    orderList: orderlistReducer,
    cartList: cartReducer
});

export default reducers

