import React from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import MyAccount from "./pages/MyAccount";
import LoginRegister from "./pages/LoginRegister";
import ErrorPage from "./pages/ErrorPage";
import Wishlist from "./pages/Wishlist";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Shop from "./pages/Shop";
import Product from "./pages/Product";
import Forget from "./pages/Forget";
import Category from "./pages/Category";
import Receipt from "./pages/Receipt";
import SubCategory from "./pages/SubCategory";
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/my-account" element={<MyAccount />} />
        <Route exact path="/login-register" element={<LoginRegister />} />
        <Route exact path="*" element={<ErrorPage />} />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/product/:id" element={<Product />} />
        <Route exact path="/forget" element={<Forget />} />
        <Route exact path="/category/:id" element={<Category />} />
        <Route exact path="/subcategory/:id" element={<SubCategory />} />
        <Route exact path="/order/:uuid" element={<Receipt/>}/>
      </Routes>
    </Router>
  );
}

export default App;
