import React from "react";
import CartHero from "./CartHero";

function Main() {
  return (
    <section class="mt-50 mb-50">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <CartHero />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Main;
