import React, { useEffect, useState } from "react";
import Grid from "./Grid";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Main() {
  const params = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(Number(params.page) || 1);
  const [totalPages, setTotalPages] = useState(0);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProduct(currentPage);
    navigate(`?page=${currentPage}`);
    //eslint-disable-next-line
  }, [currentPage]);

  const getAllProduct = async (page) => {
    const requestOptions = {
      method: "GET",
      headers: { Accept: "application/json" },
      redirect: "follow",
    };

    let url = `${process.env.REACT_APP_API_URL}user/product/getBySubCategoryName/${params.id}/?page=${page}`;

    await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setProducts(result.data.products);
        setCurrentPage(result.data.page);
        setTotalPages(result.data.totalPages);
      })
      .catch((error) => console.log("error", error));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const generatePageNumbers = () => {
    let pages = [];
    for (
      let i = Math.max(1, currentPage - 2);
      i <= Math.min(currentPage + 2, totalPages);
      i++
    ) {
      pages.push(i);
    }
    return pages;
  };
  const categoryTitle = params.id
    ? `${
        params.id.charAt(0).toUpperCase() + params.id.slice(1)
      } | Your Shop Name`
    : "Shop | Your Shop Name";
  const categoryDescription = params.id
    ? `Explore our wide range of ${params.id} products. Find great deals on your favorite items today!`
    : "Explore our wide range of products. Find great deals on your favorite items today!";

  return (
    <section className="mt-50 mb-50">
      <Helmet>
        <title>Fazal Shawl | {params.id}</title>
        <meta name="description" content={categoryDescription} />
        <meta
          name="keywords"
          content="shop, online shopping, deals, products, sale"
        />
        <meta property="og:title" content={categoryTitle} />
        <meta property="og:description" content={categoryDescription} />
      </Helmet>
      <div className="container">
        <h4 className="mb-4">{params.id}</h4>
        <div className="row">
          <div className="col-lg-12">
            <Grid products={products} />
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {currentPage > 1 && (
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(1)}
                    >
                      &laquo;&laquo; First
                    </button>
                  </li>
                )}
                <li
                  className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageClick(currentPage - 1)}
                    aria-label="Previous"
                  >
                    &laquo; Previous
                  </button>
                </li>
                {generatePageNumbers().map((number) => (
                  <li
                    key={number}
                    className={`page-item ${
                      number === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(number)}
                    >
                      {number}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage >= totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageClick(currentPage + 1)}
                    aria-label="Next"
                  >
                    Next &raquo;
                  </button>
                </li>
                {currentPage < totalPages - 2 && (
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageClick(totalPages)}
                    >
                      Last &raquo;&raquo;
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Main;
