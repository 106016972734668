import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/userProfile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    first: "",
    last: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validations
    if (credentials.email.length === 0) {
      toast("Please enter Email");
      return;
    }
    if (credentials.password !== credentials.confirm_password) {
      toast("Password and Confirm Password do not match");
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("firstName", credentials.first);
      urlencoded.append("lastName", credentials.last);
      urlencoded.append("email", credentials.email);
      urlencoded.append("password", credentials.password);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/user/register`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            localStorage.setItem("id", response.data.id);
            dispatch(setUser(response.data));
            setCredentials({
              first: "",
              last: "",
              email: "",
              password: "",
              confirm_password: "",
            });

            navigate("/");
          } else if (response.status === "error") {
            toast(response.message);
            setCredentials({
              ...credentials,
              password: "",
              confirm_password: "",
            });
          } else {
            toast("Internal Server Error");
          }
        })
        .catch((error) => {
          console.log(error);
          toast("Internal Server Error");
        });
    } catch (error) {
      console.log(error);
      toast("Internal Server Error");
    }
  };
  return (
    <div className="col-lg-6">
      <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
        <div className="padding_eight_all bg-white">
          <div className="heading_s1">
            <h3 className="mb-30">Create an Account</h3>
          </div>
          <p className="mb-50 font-sm">
            Your personal data will be used to support your experience
            throughout this website, to manage access to your account, and for
            other purposes described in our privacy policy
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                required=""
                name="first"
                placeholder="First Name"
                onChange={onChange}
                value={credentials.first}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                required=""
                value={credentials.last}
                name="last"
                placeholder="Last Name"
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                required=""
                onChange={onChange}
                value={credentials.email}
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <input
                required=""
                type="password"
                name="password"
                value={credentials.password}
                placeholder="Password"
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <input
                required=""
                type="password"
                name="confirm_password"
                placeholder="Confirm password"
                value={credentials.confirm_password}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-fill-out btn-block hover-up"
                name="login"
              >
                Register
              </button>
            </div>
          </form>
          <div className="text-muted text-center">
            Already have an account? <strong>Sign in now</strong>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Register;
