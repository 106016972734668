import React, { useEffect } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Main from "../components/about/Main";
import BreadCrumb from "../components/common/BreadCrumb";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Helmet>
        <title>Fazal Shawl - Crafting Elegance and Warmth Since 1978</title>
        <meta name="description" content="Explore the timeless craftsmanship of Fazal Shawl, where heritage meets contemporary style. Discover our collection of hand-woven shawls for both men and women, meticulously crafted from the finest materials for comfort and sustainability." />
        <meta name="keywords" content="Fazal Shawl, shawl craftsmanship, hand-weaving, hand-embroidery, luxury shawls, cashmere, wool, silk, sustainable fashion, ethical manufacturing, timeless elegance, heritage craftsmanship, eco-friendly materials, artisanal quality, seasonal shawls, elegant fashion, wardrobe essentials" />
      </Helmet>
      <BreadCrumb title="About Us" />
      <Main />
      <Footer />
    </>
  );
}

export default About;
