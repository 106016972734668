import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Form() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (credentials.name.length === 0) {
      toast("Please enter Your Name");
      return;
    }
    if (credentials.email.length === 0) {
      toast("Please Enter Your Email");
      return;
    }
    if (credentials.phone.length === 0) {
      toast("Please Enter Your Phone");
      return;
    }
    if (credentials.message.length === 0) {
      toast("Please Enter Your Message");
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", credentials.name);
      urlencoded.append("email", credentials.email);
      urlencoded.append("phone", credentials.phone);
      urlencoded.append("message", credentials.message);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/support/addRecord`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast("Message Submitted Successfully");
            setCredentials({
              name: "",
              email: "",
              phone: "",
              message: "",
            });
          } else if (response.status === "error") {
            toast(response.message);
          } else {
            toast("Internal Server Error");
          }
        })
        .catch((error) => {
          console.error(error);
          toast("Internal Server Error");
        });
    } catch (error) {
      console.error(error);
      toast("Internal Server Error");
    }
  };
  return (
    <section className="pt-50 pb-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-10 m-auto">
            <div className="contact-from-area padding-20-row-col wow FadeInUp">
              <h2 className="text-brand mb-20 text-center">
                Get in touch with us
              </h2>
              <form
                onSubmit={handleSubmit}
                className="contact-form-style text-center"
              >
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="input-style mb-20">
                      <input
                        name="name"
                        onChange={onChange}
                        placeholder="Name"
                        type="text"
                        value={credentials.name}

                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-style mb-20">
                      <input
                        name="email"
                        placeholder="Your Email"
                        type="email"
                        onChange={onChange}
                        value={credentials.email}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-style mb-20">
                    <input
                        name="phone"
                        placeholder="Your Phono no"
                        type="text"
                        onChange={onChange}
                        value={credentials.phone}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="textarea-style mb-30">
                      <textarea
                        name="message"
                        onChange={onChange}
                        placeholder="Message"
                        value={credentials.message}

                      ></textarea>
                    </div>
                    <button className="submit submit-auto-width" type="submit">
                      Send message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-messege"></p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default Form;
