import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import BreadCrumb from "../components/common/BreadCrumb";
import Footer from "../components/common/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";

function Forget() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${
      process.env.REACT_APP_API_URL
    }user/user/resetPassword?email=${encodeURIComponent(email)}`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ email }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "success") {
        swal.fire("Password reset link sent. Check your email!", "", "success");
        setTimeout(() => navigate("/login-register"), 3000);
        setEmail("");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("id")) {
      navigate("/");
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <BreadCrumb title="Forget Password" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 my-5">
            <div className="login_wrap widget-taber-content p-30 background-white border-radius-10">
              <div className="padding_eight_all bg-white">
                <div className="heading_s1">
                  <h3 className="mb-30">Forget Password?</h3>
                </div>
                <form method="post" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      required
                      name="email"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-fill-out btn-block hover-up"
                      name="login"
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default Forget;
