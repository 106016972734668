import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/userProfile";
import { ToastContainer, toast } from "react-toastify";

function Hero() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem("id")) {
      navigate("/login-register");
    }
    //eslint-disable-next-line
  }, []);
  const [cartItems, setCartItems] = useState([]);
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(cart);
  }, []);

  const totalCartItems = cartItems.reduce(
    (total, item) => total + item?.quantity,
    0
  );
  const handleLogout = () => {
    localStorage.removeItem("id");
    navigate("/login-register");
    window.location.reload();
    dispatch(setUser([]));
  };
  const userData = useSelector((state) => state.userDetails.user);
  const userId = userData.id;

  const [credentials, setCredentials] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (credentials.oldPassword.length === 0) {
      toast("Please Enter Old Password");
      return;
    }
    if (
      credentials.newPassword.length !== credentials.confirmPassword.length &&
      String(credentials.newPassword) !== String(credentials.confirmPassword)
    ) {
      toast("Password Not Match");
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("userId", userId);
      urlencoded.append("oldPassword", credentials.oldPassword);
      urlencoded.append("newPassword", credentials.newPassword);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/user/updatePassword`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast(response.message);
            setCredentials({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          } else if (response.status === "error") {
            toast(response.message);
            setCredentials({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          } else {
            toast("Internal Server Error");
          }
        })
        .catch((error) => {
          console.log(error);
          toast("Internal Server Error");
        });
    } catch (error) {
      console.log(error);
      toast("Internal Server Error");
    }
  };
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/order/getByEmail/${userData.email}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setOrders(result.data);
        } else {
          console.log("Error:", result.error);
        }
      })
      .catch((error) => console.log("Error:", error));
    //eslint-disable-next-line
  }, []);

  const totalOrders = orders.length;
  return (
    <section className="pt-150 pb-150">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="row">
              <div className="col-md-4">
                <div className="dashboard-menu">
                  <ul className="nav flex-column" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="dashboard-tab"
                        data-bs-toggle="tab"
                        href="#dashboard"
                        role="tab"
                        aria-controls="dashboard"
                        aria-selected="false"
                      >
                        <i className="fi-rs-settings-sliders mr-10"></i>
                        Dashboard
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="orders-tab"
                        data-bs-toggle="tab"
                        href="#orders"
                        role="tab"
                        aria-controls="orders"
                        aria-selected="false"
                      >
                        <i className="fi-rs-shopping-bag mr-10"></i>Orders
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="account-detail-tab"
                        data-bs-toggle="tab"
                        href="#account-detail"
                        role="tab"
                        aria-controls="account-detail"
                        aria-selected="true"
                      >
                        <i className="fi-rs-user mr-10"></i>Account details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="##" className="nav-link" onClick={handleLogout}>
                        <i className="fi-rs-sign-out mr-10"></i>Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8">
                <div className="tab-content dashboard-content">
                  <div
                    className="tab-pane fade active show"
                    id="dashboard"
                    role="tabpanel"
                    aria-labelledby="dashboard-tab"
                  >
                    <div className="row">
                      <div className="col-6">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">Total Orders </h5>
                          </div>
                          <strong className="card-body text-center display-5">
                            {totalOrders}
                          </strong>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">Cart items</h5>
                          </div>
                          <strong className="card-body text-center display-5">
                            {totalCartItems}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="orders"
                    role="tabpanel"
                    aria-labelledby="orders-tab"
                  >
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">Recents Orders</h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Order #</th>
                                <th>Product Details</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Created At</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders &&
                                orders.map((order, index) => (
                                  <tr>
                                    <td>{order.orderNo}</td>
                                    <td>
                                      {order.products &&
                                        order.products.map((product, index) => (
                                          <div>
                                            {product.title} x{product.quantity}
                                          </div>
                                        ))}
                                    </td>
                                    <td>{order.totalPrice}</td>
                                    <td>{order.status}</td>
                                    <td>{order.createdAt}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="account-detail"
                    role="tabpanel"
                    aria-labelledby="account-detail-tab"
                  >
                    <div className="card">
                      <div className="card-header">
                        <h5>Account Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label>
                              First Name <span className="required">*</span>
                            </label>
                            <input
                              required=""
                              className="form-control square"
                              name="name"
                              type="text"
                              disabled
                              value={userData?.firstName}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              Last Name <span className="required">*</span>
                            </label>
                            <input
                              required=""
                              className="form-control square"
                              name="phone"
                              disabled
                              value={userData?.lastName}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label>
                              Email Address <span className="required">*</span>
                            </label>
                            <input
                              required=""
                              className="form-control square"
                              name="email"
                              type="email"
                              disabled
                              value={userData?.email}
                            />
                          </div>
                          <form onSubmit={handleSubmit}>
                            <div className="form-group col-md-12">
                              <label>
                                Current Password{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                required=""
                                className="form-control square"
                                type="password"
                                name="oldPassword"
                                onChange={onChange}
                                value={credentials.oldPassword}
                              />
                            </div>
                            <div className="form-group col-md-12">
                              <label>
                                New Password <span className="required">*</span>
                              </label>
                              <input
                                required=""
                                className="form-control square"
                                type="password"
                                name="newPassword"
                                onChange={onChange}
                                value={credentials.newPassword}
                              />
                            </div>
                            <div className="form-group col-md-12">
                              <label>
                                Confirm Password{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                required=""
                                className="form-control square"
                                type="password"
                                name="confirmPassword"
                                onChange={onChange}
                                value={credentials.confirmPassword}
                              />
                            </div>
                            <div className="col-md-12">
                              <button
                                type="submit"
                                className="btn btn-fill-out submit"
                                name="submit"
                                value="Submit"
                              >
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default Hero;
