export const ActionTypes = {
    SET_USER: "SET_USER",
    SET_BESTSELLER: "SET_BESTSELLER",
    SET_MOSTVIEW: "SET_MOSTVIEW",
    SET_NEWARIVAL: "SET_NEWARIVAL",
    SET_ALLPRODUCT: "SET_ALLPRODUCT",
    SET_ALLCATEGORIES :"SET_ALLCATEGORIES",
    SET_TOPRATED : "SET_TOPRATED",
    SET_WISHLIST : "SET_WISHLIST",
    SET_ORDERS : "SET_ORDERS",
    SET_CART : "SET_CART"

};