import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setWishList } from "../../redux/actions/wishlist";

function ShopCard({ product }) {
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.userDetails.user);

  const handleAddToWishlist = () => {
    if (userinfo.length === 0) {
      toast("You Need to Login First!!!");
    } else {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("userId", userinfo.id);
        urlencoded.append("productId", product.id);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_API_URL}user/wishlist/addToWishlist`,
          requestOptions
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status === "success") {
              toast("Product Added to Wishlist");
              dispatch(setWishList(response.data));
            } else if (response.status === "error") {
              toast(response.message);
            } else {
              toast("Internal Server Error");
            }
          })
          .catch((error) => {
            console.error(error);
            toast("Internal Server Error");
          });
      } catch (error) {
        console.error(error);
        toast("Internal Server Error");
      }
    }
  };

  const handleAddToCart = (product) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProductIndex = cart.findIndex(
      (item) => item.id === product.id
    );
    if (existingProductIndex >= 0) {
      cart[existingProductIndex].quantity += 1;
      toast("Product Qty +1");
    } else {
      cart.push({ ...product, quantity: 1 });
      toast("Product added to cart");
    }
    localStorage.setItem("cart", JSON.stringify(cart));
  };
  const calculateRatingWidth = (rating) => {
    return `${rating * 20}%`;
  };

  return (
    <div className="col-lg-4 col-md-4 col-12 col-sm-6">
      <div className="product-cart-wrap mb-30">
        <div className="product-img-action-wrap">
          <div className="product-img product-img-zoom">
            <a href={`/product/${product?.id}`}>
              <img
                className="default-img"
                src={`https://admin.fazalshawl.com/products/${product?.thumbnail}`}
                alt=""
              />
              <img
                className="hover-img"
                src={`https://admin.fazalshawl.com/products/${product?.thumbnail}`}
                alt=""
              />
            </a>
          </div>
          <div className="product-action-1"></div>
          <div className="product-badges product-badges-position product-badges-mrg">
            <span className="hot">Hot</span>
          </div>
        </div>
        <div className="product-content-wrap">
          <h2 className="pt-3">
            <a href={`/product/${product?.id}`}>{product?.title}</a>
          </h2>
          <div className="product-category">
            <a href={`product/${product?.id}`}>{product.description}</a>
          </div>
          <div className="product-rate d-inline-block">
            <div
              className="product-rating"
              style={{ width: calculateRatingWidth(product.avgRating) }}
            ></div>
          </div>
          <div className="product-price">
            <span>Rs.{product.salePrice} </span>
            {product.discount === 0 ? (
              ""
            ) : product?.quantity - product?.sales > 0 ? (
              <span className="old-price">-{product.discount}%</span>
            ) : (
              ""
            )}
          </div>
          <div className="product-action-1 show">
            <a
              href="##"
              aria-label="Add To Cart"
              className="action-btn hover-up"
              onClick={() => handleAddToCart(product)}
            >
              <i className="fi-rs-shopping-bag-add"></i>
            </a>
            <a
              href="##"
              aria-label="Add To Wishlist"
              className="action-btn hover-up"
              onClick={() => handleAddToWishlist(product.id)}
            >
              <i className="fi-rs-heart"></i>
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ShopCard;
