import React from "react";

function PopularCards({ category }) {
  return (
    <div className="card-1 col-md col-12 m-2">
      <figure className=" img-hover-scale overflow-hidden">
        <a href={`category/${category?.name}`}>
          <img src={`https://admin.fazalshawl.com/products/${category?.image}`} alt="" />
        </a>
      </figure>
      <h5>
        <a href={`category/${category?.name}`}>{category.name}</a>
      </h5>
    </div>
  );
}

export default PopularCards;
