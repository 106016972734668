import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/userProfile";
import { setWishList } from "../../redux/actions/wishlist";
import swal from "sweetalert2";


function Login() {
  useEffect(() => {
    if (localStorage.getItem("id")) {
      navigate("/");
    }
    //eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();


    // Validations
    if (credentials.email.length === 0) {
      toast("Please enter Email");
      return;
    }
    if (credentials.password.length === 0) {
      toast("Please Enter Your Password");
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("email", credentials.email);
      urlencoded.append("password", credentials.password);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_API_URL}user/user/login`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            localStorage.setItem("id", response.data.id);
            dispatch(setUser(response.data.userData));
            dispatch(setWishList(response.data.wishlist));
            setCredentials({
              email: "",
              password: "",
            });
            navigate("/");
          } else if (response.status === "error") {
            swal.fire(response.message, "", "error");
            setCredentials({
              email: "",
              password: "",
            });
          } else {
            toast("Internal Server Error");
          }
        })
        .catch((error) => {
          console.error(error);
          toast("Internal Server Error");
        });
    } catch (error) {
      console.error(error);
      toast("Internal Server Error");
    }
  };
  return (
    <div className="col-lg-5">
      <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
        <div className="padding_eight_all bg-white">
          <div className="heading_s1">
            <h3 className="mb-30">Login</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                required=""
                name="email"
                placeholder="Your Email"
                onChange={onChange}
                value={credentials.email}
              />
            </div>
            <div className="form-group">
              <input
                required=""
                type="password"
                name="password"
                placeholder="Password"
                onChange={onChange}
                value={credentials.password}
              />
            </div>
            <div className="login_footer form-group">
              <div className="chek-form">
                <div className="custome-checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="checkbox"
                    id="exampleCheckbox1"
                    value=""
                  />
                  <label className="form-check-label" for="exampleCheckbox1">
                    <span>Remember me</span>
                  </label>
                </div>
              </div>
              <Link to={'/forget'} className="text-muted">Forgot password?</Link>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-fill-out btn-block hover-up"
                name="login"
              >
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Login;
