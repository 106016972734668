import React, { useEffect, useState } from "react";
import Grid from "./Grid";
import CategorySidebar from "./CategorySidebar";
import { setAllProduct } from "../../redux/actions/allProduct";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Main() {
  const params = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(Number(params.page) || 1);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllProduct(currentPage);
    navigate(`?page=${currentPage}`);
    //eslint-disable-next-line
  }, [currentPage, params.cat]);

  const getAllProduct = async (page) => {
    setIsLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { Accept: "application/json" },
      redirect: "follow",
    };

    let url = `${process.env.REACT_APP_API_URL}user/product/getAll?page=${page}`;

    await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setAllProduct(result.data));
        setCurrentPage(result.page);
        setTotalPages(result.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const generatePageNumbers = () => {
    let pages = [];
    for (
      let i = Math.max(1, currentPage - 2);
      i <= Math.min(currentPage + 2, totalPages);
      i++
    ) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <section className="mt-50 mb-50">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 order-2 order-lg-1">
            {isLoading ? (
              <div className="row">
                {Array.from({ length: 9 }).map((_, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <Skeleton height={300} className="rounded" />
                    <Skeleton count={4} />
                  </div>
                ))}
              </div>
            ) : (
              <>
                <Grid />
                <nav aria-label="Page navigation">
                  <ul className="pagination">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={() => handlePageClick(1)}
                        >
                          &laquo;&laquo; First
                        </button>
                      </li>
                    )}
                    <li
                      className={`page-item ${
                        currentPage <= 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(currentPage - 1)}
                        aria-label="Previous"
                      >
                        &laquo; Previous
                      </button>
                    </li>
                    {generatePageNumbers().map((number) => (
                      <li
                        key={number}
                        className={`page-item ${
                          number === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageClick(number)}
                        >
                          {number}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage >= totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(currentPage + 1)}
                        aria-label="Next"
                      >
                        Next &raquo;
                      </button>
                    </li>
                    {currentPage < totalPages - 2 && (
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={() => handlePageClick(totalPages)}
                        >
                          Last &raquo;&raquo;
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              </>
            )}
          </div>
          <CategorySidebar />
        </div>
      </div>
    </section>
  );
}

export default Main;
