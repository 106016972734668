import React from "react";

function Map() {
  return (
    <section className="section-border pt-50 pb-50">
      <div className="container">
        <div className="row">
          <div className="col-md-6  mb-4 mb-md-0">
            <h4 className="mb-15 text-brand">Contact Directly</h4>
            support@fazalshawl.com
            <br />
            <abbr title="Phone">Phone:</abbr> +92 300 6057930
            <br />
            
            <abbr title="Phone">Phone:</abbr> +92 315 9400705
            <br />

            <abbr title="Phone">Tel Phone:</abbr> +92 48 3218064
            <br />
          </div>
          <div className="col-md-4">
            <h4 className="mb-15 text-brand">Shop</h4>
            Block # 9, Bangash Bara Market Sargodha
            <br />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Map;
