import React from "react";
import ShopCard from "./ShopCard";

function Grid({products}) {
  return (
    <>
      <div className="row product-grid-3">
        {products?.map((product) => (
          <ShopCard product={product} key={product.id} />
        ))}
      </div>
    </>
  );
}

export default Grid;
