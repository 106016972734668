import React, { useRef, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function ProductGallery(props) {
  const imagesData = props?.data?.images ? JSON.parse(props.data.images) : [];
  const galleryItems = [];

  const [zoomPos, setZoomPos] = useState({ x: 0, y: 0 });
  const [showZoom, setShowZoom] = useState(false);
  const galleryRef = useRef(null);

  // If a thumbnail exists, add it as the first item
  if (props?.data?.thumbnail) {
    galleryItems.push({
      original: `https://admin.fazalshawl.com/products/${props.data.thumbnail}`,
      thumbnail: `https://admin.fazalshawl.com/products/${props.data.thumbnail}`,
      originalWidth: "100%!important",
      originalHeight: "600px",
      thumbnailHeight: "100px",
      thumbnailWidth: "100px",
    });
  }

  imagesData.forEach((image) => {
    galleryItems.push({
      original: `https://admin.fazalshawl.com/products/${image}`,
      thumbnail: `https://admin.fazalshawl.com/products/${image}`,
      originalWidth: "100%!important",
      originalHeight: "600px",
      thumbnailHeight: "100px",
      thumbnailWidth: "100px",
    });
  });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPos({ x, y });
  };

  const zoomStyle = {
    transform: `scale(3)`,
    transformOrigin: `${zoomPos.x}% ${zoomPos.y}%`,
  };

  return (
    <div className="detail-gallery w-100" ref={galleryRef}>
      <ReactImageGallery
        showPlayButton={false}
        autoPlay={false}
        lazyLoad={true}
        showNav={true}
        showIndex={false}
        thumbnailPosition={"left"}
        showFullscreenButton={false}
        items={galleryItems}
        renderItem={(item) => (
          <div
            onMouseOver={() => setShowZoom(true)}
            onMouseOut={() => setShowZoom(false)}
            onMouseMove={handleMouseMove}
          >
            <img
              src={item.original}
              alt="Gallery item"
              style={item.originalStyle}
            />
            {showZoom && (
              <div
                className="zoom-effect"
                style={{
                  position: "absolute",
                  overflow: "hidden",
                  borderRadius: "50%",
                  width: "180px",
                  height: "180px",
                  top: `calc(${zoomPos.y}% - 100px)`,
                  left: `calc(${zoomPos.x}% - 100px)`,
                  border: "1px solid black",
                  pointerEvents: "none",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img src={item.original} style={zoomStyle} alt="Zoomed" />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
}

export default ProductGallery;
