import React, { useEffect } from "react";
import Header from "../components/common/Header";
import BreadCrumb from "../components/common/BreadCrumb";
import Footer from "../components/common/Footer";
import Main from "../components/shop/Main";
import { Helmet } from "react-helmet";

function Shop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <Helmet>
          <title>Fazal Shawl | Weaving Elegance & Warmth Since 1978</title>
          <meta name="description" content="Discover the art of shawl craftsmanship with Fazal Shawl. Since 1978, we've crafted elegant shawls for men and women, using the finest materials like cashmere, wool, and silk. Explore our timeless collection today." />
          <meta name="keywords" content="Fazal Shawl, cashmere shawls, wool shawls, silk shawls, hand-embroidered shawls, sustainable fashion, artisan crafted, luxury accessories" />
          <meta property="og:title" content="Fazal Shawl: Weaving Elegance & Warmth Since 1978 | Fazal Shawl" />
          <meta property="og:description" content="Discover the timeless beauty and craftsmanship of Fazal Shawl. Crafting elegance since 1978 with a commitment to sustainability and quality in every piece. Explore our collection today." />
      </Helmet>
      <Header />
      <main className="main">
        <BreadCrumb title="Shop" />
      </main>
      <Main />
      <Footer />
    </>
  );
}

export default Shop;
